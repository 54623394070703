<template>

        <section id="inversionistas" class="flex-container  offset bg-degradado-green pt-16 pb-16 ">
        
        <transition name="slide-fade" >
          <article class="flex-item" v-if="animation1">
        
            <p class="title-2 margin-right-left-1">Inversionistas</p>
            <p class="subtitle-4 margin-right-left-1">Crece con nosotros</p>
            <p class="paragraph-1 margin-right-left-1">Súmate a <span class="green-weight">revolucionar</span> el mercado de la <span class="green-weight">Minimovilidad</span> 
                    impulsando el desarrollo urbano sostenible.</p>
            
            <picture >
                  <source media="(max-width: 700px)" srcset="../assets/animacion/04/Yipi_AnimacionWeb_04_Ipad.gif">
                  <img class="img-auto space-pb-1" src="../assets/animacion/04/Yipi_AnimacionWeb_04.gif" alt="animation dashboard">
            </picture>
          </article>
        </transition>
        <Intersect @enter.once="animation1=true" @leave.once="animation1=false" :threshold="[1.0]">
          <div class="height-div">
              <transition name="slide-fade">
                <article class="flex-item " v-if="animation1">
                    <DialogInfo
                    :dialog="dialog"
                    :setDialog="setDialog"
                    />
                    <FormInversionistas
                    :fields="fields"
                    @setData="registrarInversionista()"
                    sizeClass="form-test"
                    titleClass="title-3"
                    title="¿Te interesa invertir en Yipi?"
                    />
                </article>
              </transition>
          </div>
        </Intersect>

        </section>
</template>
<style scoped>
  .space-pb-1{
    padding-bottom: 10px;
  }
</style>
<script>
  import Intersect from 'vue-intersect'
import FormInversionistas from "../components/commons/Form.vue";
import DialogInfo from "../components/commons/DialogInfo.vue";
import {postApiResource} from '../api/requestApi';
import {
  emailValidation,
  nombreValidation,
  telefonoValidation,
  giroValidation,
  apellidosValidation,
} from "@/utils/validation";
export default{
        data: () => ({
    animation1:false,
    checkbox: false,
    valid: true,
    dialog: false,
    inversionistaData: {
      nombre: "",
      apellidos: "",
      telefono: "",
      correo: "",
      giro: "",
    },
    fields: [
      {
        cols: "6",
        label: "Nombre",
        rules: nombreValidation,
        vmodel: "",
        index: 1,
      },
      {
        cols: "6",
        label: "Apellidos",
        rules: apellidosValidation,
        vmodel: "",
        index: 2,
      },
      {
        cols: "12",
        label: "Teléfono",
        rules: telefonoValidation,
        icon: "$telefono",
        vmodel: "",
        index: 3,
      },
      {
        cols: "12",
        label: "Email",
        rules: emailValidation,
        icon: "$email",
        vmodel: "",
        index: 4,
      },
      {
        cols: "12",
        label: "Giro de tu empresa",
        icon: "$portafolio",
        rules: giroValidation,
        vmodel: "",
        index: 5,
      },
    ],
}),
    components: {
      Intersect,
    DialogInfo,
    FormInversionistas,
    },
    methods:{
        async registrarInversionista() {
          console.log('test')
      const inversionistaData = {
        nombre: this.fields[0].vmodel,
        apellidos: this.fields[1].vmodel,
        telefono: this.fields[2].vmodel,
        correo: this.fields[3].vmodel,
        giro: this.fields[4].vmodel,
      };

    const {data} = await postApiResource("/request-page/inversionista",inversionistaData);

        if (data.statusCode == 200){
           this.dialog=true;
        } 

    },
    setDialog() {
      this.dialog = !this.dialog;
    },
    setCheckbox(value) {
      this.checkbox = value;
    },
    }
}
 
</script>
